import { createAsyncAction } from '../utils/actionUtils';

export const SIGN_IN_USER = createAsyncAction('SIGN_IN_USER');
export const SIGN_UP_USER = createAsyncAction('SIGN_UP_USER');
export const SIGN_OUT_USER = 'SIGN_OUT_USER';
export const VERIFY_AUTH = 'VERIFY_AUTH';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_USER = 'AUTH_USER';
export const FACEBOOK_AUTH = 'FACEBOOK_AUTH';
export const GOOGLE_AUTH = 'GOOGLE_AUTH';

export function signUpUser(values, callback) {
  return {
    type: SIGN_UP_USER.FETCH,
    values,
    callback,
  };
}

export function signInUser(values, callback) {
  return {
    type: SIGN_IN_USER.FETCH,
    values,
    callback,
  };
}

export function signInWithFacebook(callback) {
  return {
    type: FACEBOOK_AUTH,
    callback,
  };
}

export function signInWithGoogle(callback) {
  return {
    type: GOOGLE_AUTH,
    callback,
  };
}

export function authUser(user, tokenId, callback) {
  return {
    type: AUTH_USER,
    token_id: tokenId,
    user,
    callback,
  };
}

export function authError(error, callback) {
  return {
    type: AUTH_ERROR,
    error,
    callback,
  };
}

export function verifyAuth() {
  return {
    type: VERIFY_AUTH,
  };
}

export function signOutUser(callback) {
  return {
    type: SIGN_OUT_USER,
    callback,
  };
}
