import React from 'react';

function Footer() {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        padding: 16,
        width: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.2) 2px 2px 10px 1px',
        // backgroundColor: '#ffffff',
      }}
    >
      <span style={{ float: 'right' }}>
        {/* <a href="https://help.boxpressd.com/support/home" style={{ marginRight: 12 }}>Help</a> */}
        <a
          href="https://sgro.freshdesk.com/support/home"
          style={{ marginRight: 12, color: '#686868', fontWeight: 500 }}
        >
          {'Help'}
        </a>
        <a
          href="https://boxpressd.com/legal/privacy"
          style={{ marginRight: 12, color: '#686868', fontWeight: 500 }}
        >
          {'Privacy'}
        </a>
        <a
          href="https://boxpressd.com/legal/terms"
          style={{ marginRight: 12, color: '#686868', fontWeight: 500 }}
        >
          {'Terms'}
        </a>
      </span>
    </div>
  );
}

export default Footer;
