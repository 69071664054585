import React, { useEffect } from 'react';
import axios from 'axios';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

export default function () {
  useEffect(() => {
    console.debug('Getting Twitter token..');
    // INFO https://developer.twitter.com/en/docs/authentication/api-reference/request_token
    const urlParams = new URLSearchParams(window.location.search);
    const redirectPath = urlParams.get('path');
    const userId = urlParams.get('userId');
    const state = btoa(JSON.stringify({
      path: redirectPath,
      userId: userId,
    }));
    axios.post(`${config.apiEndPoint}/auth/twitter/request_token`, {}, {
      params: { state },
    }).then((res) => {
      const token = res.data.oauth_token;
      window.location.href = `https://api.twitter.com/oauth/authorize?oauth_token=${token}&force_login=1`;
    }).catch((err) => {
      console.error(err);
    });
  }, []);
  return <div />;
}
