import React from 'react';
import { isMobile } from 'react-device-detect';
import { Row, Col } from 'reactstrap';
import ConfirmPasswordForm from './ConfirmPasswordForm';
import ResetPasswordForm from '../ResetPassword/ResetPasswordForm';

const FormCard = () => {
  if (isMobile) {
    return (
      <section className="cover cover-page">
        <div
          className="cover-bg-img"
          style={{ backgroundImage: 'url(//cdn.boxpressd.io/placeholder/side-background.jpg)' }}
        />
        <div className="cover-form-container">
          <div className="col-12 col-md-8 col-lg-6 col-xl-4">
            <ConfirmPasswordForm />
          </div>
        </div>
      </section>
    );
  }
  return (
    <Row className="form-card-page form-card no-gutters" style={{ height: '100vh', margin: 0 }}>
      <Col
        lg={6}
        className="form-card__img form-card__img--left"
        style={{ backgroundImage: "url('//cdn.boxpressd.io/placeholder/side-background.jpg')", maxWidth: 550, height: '100vh' }}
      />
      <Col lg={6} className="form-card__body p-5 px-lg-8 d-flex align-items-center">
        <ResetPasswordForm />
      </Col>
    </Row>
  );
};

const Login = () => (
  <div key="1">
    <FormCard />
  </div>
);

export default Login;
