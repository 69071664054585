import React, { useEffect } from 'react';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

export default function () {
  useEffect(() => {
    const clientId = 'GPIFBUYK35VDT1QLMRWLSBMSNA4T3EUVMJZF41PMGVMVMTNT';
    const redirectUrl = `${config.apiEndPoint}/auth/foursquare`;
    const urlParams = new URLSearchParams(window.location.search);
    const redirectPath = urlParams.get('path');
    const userId = urlParams.get('userId');
    const state = btoa(JSON.stringify({
      path: redirectPath,
      userId: userId,
    }));
    window.location.href = `https://foursquare.com/oauth2/authenticate?client_id=${clientId}&response_type=code&state=${state}&redirect_uri=${redirectUrl}`;
  }, []);
  return <div />;
}
