import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';

class IosModal extends PureComponent {
  render() {
    const {
      show,
      title,
      content,
      className,
      buttons,
    } = this.props;

    return (
      <div style={{ display: show ? 'block' : 'none' }} className={classNames('react-modal', className)}>
        <div className={classNames('webkit-sassui-backdrop', className)} />
        <div
          className={classNames(
            'webkit-sassui-ios-alert webkit-sassui-ios-modal react-ios-modal',
            className
          )}
        >
          {title && (
            <header hidden={!title} className="hd">
              {title}
            </header>
          )}
          {content && <section className="bd">{content}</section>}
          {buttons.length > 0 && (
            <footer className="ft">
              {buttons.map((item, index) => (
                <button key={index} onClick={item.onClick}>
                  {item.label}
                </button>
              ))}
            </footer>
          )}
        </div>
      </div>
    );
  }
}

IosModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  onDismiss: PropTypes.func,
};

IosModal.defaultProps = {};

export default IosModal;
