import React from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import ls from 'local-storage';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import App from './app';
import { signOutUser } from './auth/actions';
import reducers from './reducers';
import sagas from './sagas';
import packageJson from '../package';

import firebaseApp from '../config/firebase.config';

import 'sweetalert/dist/sweetalert.css';
import './index.scss';
import { isMobile } from 'react-device-detect';

const cookies = new Cookies();

const env = process.env.NODE_ENV || 'development';
const config = require('../config/config.json')[env];

const urlParams = new URLSearchParams(window.location.search);
if (urlParams.get('action') === 'sign_out') {
  firebaseApp.auth().signOut();
}

if (ls('token_id')) {
  axios.defaults.headers.common.Authorization = `Bearer ${ls('token_id')}`;
}

axios.interceptors.response.use((response) => response, async (error) => {
  console.log('=== Axios Error ===');
  console.log(error.config);
  console.log(error);
  const userToken = urlParams.get('token');
  const originalRequest = error.config;

  // if (error.response && error.response.status === 403 && !originalRequest.expired) {
  if (error.response && error.response.status === 403) {
    originalRequest.expired = false;
    if (error.response.data.code && error.response.data.code === 'auth/id-token-expired') {
      console.log('The token is expired.');
      originalRequest.expired = true;

      if (firebaseApp.auth().currentUser) {
        const tokenId = await firebaseApp.auth().currentUser.getIdToken(true);
        ls('token_id', tokenId);
        console.log('Got new token in interceptor');
        console.log(tokenId);
        // eslint-disable-next-line no-param-reassign
        error.config.headers.Authorization = `Bearer ${tokenId}`;
        // eslint-disable-next-line no-param-reassign
        error.config.baseURL = undefined;
        return axios.request(error.config);
        // window.location.replace(`${config.authEndPoint}/login?path=${window.location.href.split('?')[0]}&expired=true`);
      }
    }
    if (userToken) {
      console.log('Trying call with token from query param:');
      console.log(userToken);
      // eslint-disable-next-line no-param-reassign
      error.config.headers.Authorization = `Bearer ${userToken}`;
      // eslint-disable-next-line no-param-reassign
      error.config.baseURL = undefined;
      return axios.request(error.config);
    }
    const tokenId = ls('token_id');
    originalRequest.expired = false;
    error.config.headers.Authorization = `Bearer ${tokenId}`;
    // eslint-disable-next-line no-param-reassign
    error.config.baseURL = undefined;
    return axios.request(error.config);
  }
  // Will redirect to auth service
  return Promise.reject(error);
});

window.redirect = () => {
  const redirectPath = urlParams.get('path') || config.mainEndPoint;
  const tokenId = ls.get('token_id');
  if (tokenId) {
    console.log(`Redirecting to ${redirectPath}`);
    let path;
    if (redirectPath.indexOf('?') !== -1) {
      path = `${redirectPath}&token=${tokenId}`;
    } else {
      path = `${redirectPath}?token=${tokenId}`;
    }
    // FIXME If native, this doesn't work. We need to close the auth window/tab somehow
    window.location.replace(path);
  }
};

firebaseApp.auth().onAuthStateChanged((user) => {
  if (user) {
    firebaseApp.auth().currentUser.getIdToken().then((tokenId) => {
      console.log('Got new token ID:');
      console.log(tokenId);
      axios.defaults.headers.common.Authorization = `Bearer ${tokenId}`;
      // FIXME We don't need it stored both as cookie and in local storage - pick one or the other and user in all services
      cookies.set('tokenId', tokenId);
      ls.set('token_id', tokenId);
      const expired = true; // TODO Need a way to actually determine this - maybe a last sign in timestamp in ls?
      ls.set('expired', expired);
      if (!expired) {
        window.redirect();
      } else {
        console.log('Token is expired...');
      }
    }).catch((err) => {
      console.log(err);
    });
  } else {
    console.log('User is logged out!');
    const cachedUser = ls('user');
    if (!cachedUser) {
      // FIXME Looks like I never import this anywhere - where is it exported?
      // history.push('/register');
    }
  }
});

const sagaMiddleware = createSagaMiddleware();

const middlewares = [
  sagaMiddleware,
];

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
const store = createStoreWithMiddleware(reducers);

if (urlParams.get('action') === 'sign_out') {
  store.dispatch(signOutUser());
}

sagaMiddleware.run(sagas);

const theme = createMuiTheme({
  palette: {
    mode: isMobile ? 'dark' : 'light',
    primary: {
      main: '#d6c290',
    },
    secondary: {
      main: '#17191d',
    },
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: 12,
      },
    },
  },
});

Sentry.init({
  dsn: 'https://95646c2da86e433fbc619e1ea84262d4@sentry.io/2449657',
  environment: process.env.NODE_ENV,
  release: packageJson.version,
});

// FIXME Prevent render if already logged in / cookie available? Show loading spinner?
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
