import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];
const apiKeys = require('../../../config/apiKeys.json')[env];

const appId = apiKeys.facebookAppId;
export default function () {
  useEffect(() => {
    const redirectUrl = `${config.apiEndPoint}/auth/facebook`; // TODO Use config to build these
    const cancelUrl = `${config.apiEndPoint}/auth/facebook`;
    // Potentially also `pages_manage_posts` scope for businesses - may just attach this permission through the business panel separately
    const urlParams = new URLSearchParams(window.location.search);
    const redirectPath = urlParams.get('path');
    const userId = urlParams.get('userId');
    const state = btoa(JSON.stringify({
      path: redirectPath,
      userId,
    }));
    const sub = isMobile ? 'm' : 'www';
    const facebookAuth = encodeURIComponent(`https://${sub}.facebook.com/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUrl}&cancel_url=${cancelUrl}&scope=email,public_profile&state=${state}`);
    window.location.href = `https://${sub}.facebook.com/login.php?skip_api_login=1&api_key=${appId}&kid_directed_site=0&app_id=${appId}&signed_next=1&next=${facebookAuth}&display=page&locale=en_US&pl_dbl=0`;
  }, []);

  return (
    <div />
  );
}
