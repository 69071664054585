import Login from './auth/Login';
import SignUp from './auth/SignUp';
import ResetPassword from './auth/ResetPassword';
import ConfirmPassword from './auth/ConfirmPassword';
import ChangePassword from './auth/ChangePassword';
import Social from './auth/Social';
const { Facebook, Twitter, Untappd, Foursquare } = Social;

export default {
  '/facebook': Facebook,
  '/twitter': Twitter,
  '/untappd': Untappd,
  '/foursquare': Foursquare,
  '/login': Login,
  '/register': SignUp,
  '/forgot-password': ResetPassword,
  '/password/confirm': ConfirmPassword,
  '/password/reset': ChangePassword,
};
