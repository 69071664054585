import React from 'react';
import { isMobile } from 'react-device-detect';
import SignUpForm from './SignUpForm';

const FormCard = () => {
  if (isMobile) {
    return (
      <section className="cover cover-page">
        <div
          className="cover-bg-img"
          style={{ backgroundImage: 'url(//cdn.boxpressd.io/placeholder/side-background.jpg)' }}
        />
        <div className="cover-form-container">
          <div className="col-12 col-md-8 col-lg-6 col-xl-4">
            <SignUpForm />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="form-card-page form-card row no-gutters" style={{ height: '100vh', margin: 0 }}>
      <div
        className="form-card__img form-card__img--left col-lg-6"
        style={{ backgroundImage: "url('//cdn.boxpressd.io/placeholder/side-background.jpg')", maxWidth: 550, height: '100vh' }}
      />
      <div className="form-card__body col-lg-6 p-5 px-lg-8 d-flex align-items-center">
        <SignUpForm />
      </div>
    </section>
  );
};

const Login = () => (
  <div key="1">
    <FormCard />
  </div>
);

export default Login;
