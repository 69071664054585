import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import SweetAlert from 'sweetalert-react';
import { Button } from 'reactstrap';
import {isAndroid, isIOS, isMobile, isMobileOnly} from 'react-device-detect';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import FlatButton from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import MaterialButton from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField';
import firebaseApp from '../../../config/firebase.config';
import IosAlert from '../../components/IosModal';
import { language, messages } from '../../utils/localeUtils';
import inputStyle from '../../utils/inputStyle';

function NormalLoginForm(props) {
  const [alert, setAlert] = useState({
    email: '',
    type: 'info',
    show: false,
  });
  const [user, setUser] = useState({
    email: '',
  });
  const [submitted, setSubmitted] = useState(false);

  const getValidationState = (type) => {
    switch (type) {
      case 'email': {
        if (submitted) {
          if (user.email.length === 0) {
            alert.email = 'Please enter your email';
            // setState({
            //   error: error
            // });
            return 'error';
          }
          // eslint-disable-next-line no-useless-escape
          const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(String(user.email).toLowerCase())) {
            alert.email = 'This is not a valid email';
            // setState({
            //   error: error
            // });
            return 'error';
          }
        }
        alert.email = '';
        alert.password = '';
        return null;
      }
      default: {
        return null;
      }
    }
  };

  const hideAlertDialog = () => {
    setAlert((prevAlert) => ({
      ...prevAlert,
      show: false,
    }));
  };

  const handleUserChange = (key) => (e) => {
    const updatedUser = { ...user };
    updatedUser[key] = e.target.value;
    setUser(updatedUser);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    validateFields().then(async (values) => {
      console.log('Received values of form: ', values);
      await firebaseApp.auth().sendPasswordResetEmail(values.email).then(() => {
        setAlert({
          show: true,
          title: 'Please Check Your Email',
          type: 'success',
          message: `Instructions for resetting your password have been sent to ${values.email}`,
          submitted: false,
        });
      }).catch((err) => {
        console.log(err);
        setAlert({
          show: true,
          title: 'There was a problem',
          type: 'error',
          message: err.message,
          submitted: false,
        });
      });
    });
  };

  const validateFields = () => new Promise((resolve, reject) => {
    if (getValidationState('email') === 'error') {
      reject();
    } else {
      resolve({
        email: user.email,
      });
    }
  });

  const renderErrorAlert = () => {
    // if (isMobile) {
    //   return (
    //     <AlertDialog
    //       isOpen={alert.show}
    //       isCancelable={false}>
    //       <div className='alert-dialog-title'>{alert.title}</div>
    //       <div className='alert-dialog-content'>
    //         {alert.message}
    //       </div>
    //       <div className='alert-dialog-footer'>
    //         {/*<button onClick={hideAlertDialog} className='alert-dialog-button'>*/}
    //         {/*  Cancel*/}
    //         {/*</button>*/}
    //         <button onClick={hideAlertDialog} className='alert-dialog-button'>
    //           Ok
    //         </button>
    //       </div>
    //     </AlertDialog>
    //   )
    // } else {
    if (isIOS) {
      const items = [{
        label: 'Dismiss',
        onClick: () => {
          hideAlertDialog();
        },
      }];
      return (
        <IosAlert
          show={alert.show}
          title={alert.title}
          content={alert.message}
          buttons={items}
          onDismiss={() => {}}
        />
      );
    }
    if (isAndroid) {
      const actions = [
        <FlatButton
          label="Dismiss"
          primary
          onClick={hideAlertDialog}
        />,
      ];
      return (
        <Dialog
          title={alert.title}
          actions={actions}
          modal={false}
          open={alert.show}
          onRequestClose={hideAlertDialog}
        >
          <DialogTitle id="alert-dialog-title">{alert.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {alert.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MaterialButton onClick={hideAlertDialog} color="secondary">
              Dismiss
            </MaterialButton>
          </DialogActions>
        </Dialog>
      );
    }
    return (
      <SweetAlert
        show={alert.show}
        title={alert.title}
        text={alert.message}
        type={alert.type}
        onConfirm={hideAlertDialog}
        onOutsideClick={hideAlertDialog}
      />
    );
    // }
  };

  const urlParams = new URLSearchParams(window.location.search);
  const redirectPath = urlParams.get('path');
  const source = urlParams.get('source');
  const logoSrc = source && source === 'cnm'
    ? (isMobileOnly ? 'https://cdn.cigarsnearme.com/assets/logos/cnm-powered-by-dark.png' : 'https://cdn.cigarsnearme.com/assets/logos/cnm-powered-by.png')
    : 'https://boxpressd.com/4a1bc988e13abc66d4e8c1ad9bb0f61b.png';

  return (
    <section className="align-items-center">
      <div style={{ maxWidth: 550 }}>
        <div style={{ textAlign: 'center' }}>
          <img
            src={logoSrc}
            alt="Boxpressd"
            style={{ maxWidth: 220, marginBottom: 20 }}
          />
        </div>
        <p className="lead dark" style={{ color: isMobile ? '#efefef' : 'inherit', fontSize: '1.2em' }}>
          {messages[language]?.forgot_your_password || 'Forgot your password?'}
        </p>
        <p className="dark" style={{ color: isMobile ? '#efefef' : 'inherit', marginTop: -10, marginBottom: 20 }}>
          {messages[language]?.password_reset_instructions || 'Enter the email address you used when you joined and we’ll send you instructions to reset your password.'}
        </p>

        <TextField
          // required
          name="email"
          error={alert.email}
          style={{ width: '100%' }}
          inputProps={{ style: inputStyle }}
          // variant="standard"
          label={messages[language]?.email || 'Email'}
          helperText={alert.email || ''}
          value={user.email}
          onChange={handleUserChange('email')}
        />
        <Button
          style={{ marginTop: 20 }}
          className="btn-cta btn-block btn-primary"
          onClick={handleSubmit}
        >
          {messages[language]?.send_instructions || 'Send Reset Instructions'}
        </Button>
        <p className="additional-info" style={{ color: isMobile ? '#efefef' : 'inherit', fontSize: '1em', marginTop: 12, textAlign: 'center' }}>
          <Link to={{ pathname: '/login', search: `?path=${redirectPath}` }}>{messages[language]?.back_to_login || 'Back to Login'}</Link>
        </p>

        { renderErrorAlert() }
      </div>
    </section>
  );
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)(NormalLoginForm));
