import firebaseApp from '../../config/firebase.config';
import axios from 'axios';
import firebase from 'firebase';
import { put, takeLatest } from 'redux-saga/effects';
import {
  authUser,
  authError,
  signOutUser,
  SIGN_UP_USER,
  SIGN_IN_USER,
  VERIFY_AUTH,
  FACEBOOK_AUTH,
  GOOGLE_AUTH,
} from './actions';
import * as Sentry from '@sentry/browser';

const env = process.env.NODE_ENV || 'development';
const config = require('../../config/config.json')[env];

export function* signUpUser(action) {
  const { values } = action;

  try {
    const firebaseResponse = yield firebaseApp.auth().createUserWithEmailAndPassword(values.email, values.password);
    const tokenId = yield firebaseApp.auth().currentUser.getIdToken();

    // Set the Axios bearer token now that we have the Firebase token, we need it to create the user
    // INFO I prefer it in the one call below so it doesn't accidentally get reused by another user
    axios.defaults.headers.common.Authorization = `Bearer ${tokenId}`;

    // TODO Link display name to Firebase user
    console.log('Firebase user:');
    console.log(firebaseResponse.user);

    const newUser = {
      email: values.email,
      firebase_id: firebaseResponse.user.uid,
      first_name: values.first_name,
      last_name: values.last_name,
      allow_newsletter: true, // FIXME? Especially for GDPR, they need to explicitly allow
      source: values.source,
    };

    console.log('User to be created:');
    console.log(newUser);

    const response = yield axios.post(`${config.apiEndPoint}/users/create`, newUser);

    const currentUser = response.data;

    if (currentUser && currentUser.id) {
      yield put(authUser(currentUser, tokenId, action.callback));
    } else {
      Sentry.captureMessage(JSON.stringify(response));
      yield put(authError({
        show: true,
        title: 'Unable To Sign Up',
        message: 'An error occurred. Please try again later',
        submitted: false,
      }, action.callback));
    }
  } catch (error) {
    Sentry.captureException(error);
    console.log('Unable sign user up:');
    console.log(error);
    yield put(authError({
      show: true,
      title: 'Unable To Sign Up',
      message: error.message,
      submitted: false,
    }, action.callback));
  }
}

export function* signUpUserWatcher() {
  yield takeLatest(SIGN_UP_USER.FETCH, signUpUser);
}

export function* signInUser(action) {
  const { values } = action;
  console.log(action);
  try {
    const user = yield firebaseApp.auth().signInWithEmailAndPassword(values.email, values.password);
    console.log(JSON.stringify(user));
    const data = yield firebaseApp.auth().currentUser.getIdToken();
    // console.log(JSON.stringify(data));

    const response = yield axios.get(`${config.apiEndPoint}/users/current`);

    const currentUser = response.data;

    console.log(JSON.stringify(currentUser));

    if (currentUser && currentUser.id) {
      yield put(authUser(currentUser, data, action.callback));
    } else {
      Sentry.captureMessage(JSON.stringify(response));
      yield put(authError({
        show: true,
        title: 'Unable To Sign In',
        message: 'An error occurred. Please try again later',
        submitted: false,
      }, action.callback));
    }
  } catch (error) {
    Sentry.captureException(error);
    console.log('Unable to sign user in:');
    console.log(error);
    yield put(authError({
      show: true,
      title: 'Unable To Log In',
      message: error.message,
      submitted: false,
    }, action.callback));
  }
}

export function* signInUserWatcher() {
  yield takeLatest(SIGN_IN_USER.FETCH, signInUser);
}

export function* signInWithFacebook(action) {
  try {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('email,user_photos');

    const result = yield firebaseApp.auth().signInWithPopup(provider);
    console.log(JSON.stringify(result));

    // const accessToken = result.credential.accessToken;

    const { user } = result;
    console.log(user);
    console.log(user.providerData);

    const facebookId = user.providerData[0].uid; // FIXME Need to iterate to find the correct 1 if length > 0 - Firebase links multiple providers to one email
    const username = user.displayName;
    const tokenId = yield firebaseApp.auth().currentUser.getIdToken();

    axios.defaults.headers.common.Authorization = `Bearer ${tokenId}`;

    const urlParams = new URLSearchParams(window.location.search);
    const source = urlParams.get('source') || 'boxpressd';

    const response = yield axios.post(`${config.apiEndPoint}/users/create`, {
      first_name: username.split(' ')[0],
      last_name: username.split(' ')[1],
      username,
      email: user.email,
      firebase_id: result.uid,
      facebook_id: facebookId,
      source,
    });

    const currentUser = response.data;

    console.log(JSON.stringify(currentUser));

    if (currentUser && currentUser.id) {
      yield put(authUser(currentUser, tokenId, action.callback));
    } else {
      yield put(authError({
        show: true,
        title: 'Unable To Sign In',
        message: 'An error occurred. Please try again later',
        submitted: false,
      }, action.callback));
    }
  } catch (error) {
    if (error.code === 'auth/popup-closed-by-user' && error.code === 'auth/cancelled-popup-request') {
      // These are silent errors
      yield put(authError({
        show: false,
        submitted: false,
      }, action.callback));
    } else {
      Sentry.captureException(error);
      console.log('Unable to sign user in:');
      console.log(error);
      yield put(authError({
        show: true,
        title: 'Unable To Log In',
        message: error.message,
        submitted: false,
      }, action.callback));
    }
  }
}

export function* signInFacebookWatcher() {
  yield takeLatest(FACEBOOK_AUTH, signInWithFacebook);
}

export function* signInWithGoogle(action) {
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');

    const result = yield firebaseApp.auth().signInWithPopup(provider);
    console.log(JSON.stringify(result));

    // const accessToken = result.credential.accessToken;

    const { user } = result;
    console.log(user);
    console.log(user.providerData);

    const googleId = user.providerData[0].uid; // FIXME Need to iterate to find the correct 1 if length > 0 - Firebase links multiple providers to one email
    const username = user.displayName;
    const tokenId = yield firebaseApp.auth().currentUser.getIdToken();

    axios.defaults.headers.common.Authorization = `Bearer ${tokenId}`;

    const response = yield axios.post(`${config.apiEndPoint}/users/create`, {
      first_name: username.split(' ')[0],
      last_name: username.split(' ')[1],
      username,
      email: user.email,
      firebase_id: result.uid,
      google_id: googleId,
    });

    const currentUser = response.data;

    console.log(JSON.stringify(currentUser));

    if (currentUser && currentUser.id) {
      yield put(authUser(currentUser, tokenId, action.callback));
    } else {
      yield put(authError({
        show: true,
        title: 'Unable To Sign In',
        message: 'An error occurred. Please try again later',
        submitted: false,
      }, action.callback));
    }
  } catch (error) {
    if (error.code === 'auth/popup-closed-by-user' && error.code === 'auth/cancelled-popup-request') {
      // These are silent errors
      yield put(authError({
        show: false,
        submitted: false,
      }, action.callback));
    } else {
      Sentry.captureException(error);
      console.log('Unable to sign user in:');
      console.log(error);
      yield put(authError({
        show: true,
        title: 'Unable To Log In',
        message: error.message,
        submitted: false,
      }, action.callback));
    }
  }
}

export function* signInGoogleWatcher() {
  yield takeLatest(GOOGLE_AUTH, signInWithGoogle);
}

export function verifyAuth() {
  return (dispatch) => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log('User is logged in!');
        // FIXME Only case where user checks "Keep me logged in" -- this will continue to keep their auth active with Firebase
        firebaseApp.auth().currentUser.getIdToken()
          .then((tokenId) => {
            console.log('Got new token ID:');
            console.log(tokenId);
            // FIXME This is wrong - the user object here isn't the one we want to store - maybe updateAuth() to update token ID?
            // dispatch(authUser(currentUser));
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log('User is logged out!');
        dispatch(signOutUser());
      }
    });
  };
}

export function* verifyAuthWatcher() {
  yield takeLatest(VERIFY_AUTH, verifyAuth);
}

export default [
  signUpUserWatcher,
  signInUserWatcher,
  verifyAuthWatcher,
  signInFacebookWatcher,
  signInGoogleWatcher,
];
