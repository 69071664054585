import React, { useEffect } from 'react';

const env = process.env.NODE_ENV || 'development';
const config = require('../../../config/config.json')[env];

export default function () {
  useEffect(() => {
    const clientId = '26C4E475DB4E701F03993E27F7337DA3ACCBEE3F';
    const redirectUrl = `${config.apiEndPoint}/auth/untappd`;
    const urlParams = new URLSearchParams(window.location.search);
    const redirectPath = urlParams.get('path');
    const userId = urlParams.get('userId');
    const state = btoa(JSON.stringify({
      path: redirectPath,
      userId: userId,
    }));
    window.location.href = `https://untappd.com/oauth/authenticate/?client_id=${clientId}&response_type=code&state=${state}&redirect_url=${redirectUrl}`;
  }, []);
  return <div />;
}
