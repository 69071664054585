// FIXME This still won't work to remove the yellow autofill
//  https://stackoverflow.com/questions/54706919/material-ui-remove-the-yellow-background-on-textfield-autofill
export default {
  input: {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px transparent inset',
      backgroundColor: 'transparent',
      '&:focus': {
        WebkitBoxShadow: '0 0 0 1000px transparent inset',
        backgroundColor: 'transparent',
      },
      '&:hover': {
        WebkitBoxShadow: '0 0 0 1000px transparent inset',
        backgroundColor: 'transparent',
      },
      '&:active': {
        WebkitBoxShadow: '0 0 0 1000px transparent inset',
        backgroundColor: 'transparent',
      },
    },
  },
};
