import React from 'react';
import PropTypes from 'prop-types';
import ls from 'local-storage';
import { isMobile } from 'react-device-detect';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import { Card } from 'reactstrap';
import RoutesList from './routes';
import Footer from './components/Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: isMobile ? 'none' : 'rgba(0, 0, 0, 0.2) 2px 2px 10px 1px',
    padding: 16,
  },
}));

const getProfileImage = (user) => {
  if (user.image_url) {
    return user.image_url.replace('/users/profile/', '/users/profile/56x56/');
  }
  return null;
};

// TODO If the user is already logged in and access any route, we should handle whether to redirect them to the initiating
//  URL or show an option to sign back in to their account or sign in to another
function App(props) {
  const classes = useStyles();
  const { location } = props;
  const user = ls('user');
  // const expired = ls('expired');

  if (user && (location.pathname === '/login' || location.pathname === '/register')) {
    return (
      // FIXME This should have a dark mode color scheme too
      <div style={{ backgroundColor: '#ffffff', paddingTop: 40, height: '100vh', width: '100vw' }} className="choose-account">
        <Card className={classes.root}>
          <div style={{ textAlign: isMobile ? 'left' : 'center' }}>
            <img
              src="https://boxpressd.com/4a1bc988e13abc66d4e8c1ad9bb0f61b.png"
              alt="Boxpressd"
              style={{ maxWidth: 220 }}
            />
          </div>
          <h3>Choose account</h3>
          <List>
            <ListItem
              style={{ cursor: 'pointer' }}
              onClick={() => {
                /*
                 TODO
                  If the user is seeing this view because their token expired, we prob want to do like Google and have
                  them enter their password again. Although, we really need to check what provider they used to know whether
                  to ask for password or have them sign in through that provider again
                */
                // if (expired) {
                //   history.push('/confirm')
                // } else {
                window.redirect();
                // }
              }}
            >
              <ListItemAvatar>
                <Avatar src={getProfileImage(user)}>{user.first_name.charAt(0)}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={user.full_name || `${user.first_name} ${user.last_name}`} secondary={user.email} />
            </ListItem>
            <ListItem
              style={{ cursor: 'pointer' }}
              onClick={() => {
                ls.clear();
                props.history.push('/login');
              }}
            >
              <ListItemAvatar>
                <Avatar>
                  <AddIcon style={{ height: 22, width: 22 }} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Sign In To Another Account" secondary={`You will be signed out of ${user.email}`} />
            </ListItem>
          </List>
        </Card>
        <Footer />
      </div>
    );
  }
  return (
    <Switch
      location={location}
    >
      {Object.keys(RoutesList).map((path) => {
        const RouteInner = RoutesList[path];

        return (
          <Route
            key={path}
            path={path}
            exact
            render={() => (
              <RouteInner />
            )}
          />
        );
      })}

      { /* 404 */}
      <Route
        render={() => (
          <Redirect to="/register" />
        )}
      />
    </Switch>
  );
}

App.propTypes = {
  location: PropTypes.object,
};

App.defaultProps = {
  location: {},
};

export default withRouter(App);
