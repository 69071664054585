import React from 'react';
import { withRouter } from 'react-router-dom';
import SweetAlert from 'sweetalert-react';
import { Button, FormGroup, Input, FormText } from 'reactstrap';
import {isAndroid, isIOS, isMobile, isMobileOnly} from 'react-device-detect';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import FlatButton from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import MaterialButton from '@material-ui/core/Button/Button';
import firebaseApp from '../../../config/firebase.config';
import Icon from '../../components/Icon';
import IosAlert from '../../components/IosModal';

const urlParams = new URLSearchParams(window.location.search);
const source = urlParams.get('source');
const logoSrc = source && source === 'cnm'
  ? (isMobileOnly ? 'https://cdn.cigarsnearme.com/assets/logos/cnm-powered-by-dark.png' : 'https://cdn.cigarsnearme.com/assets/logos/cnm-powered-by.png')
  : 'https://boxpressd.com/4a1bc988e13abc66d4e8c1ad9bb0f61b.png';

// FIXME This form isn't complete - it expects the user is "signed in" to Boxpressd, but not Firebase, so it should
//  pull the email address from local storage and accept a password to run against Firebase to confirm
//  As of right now, it's not necessary and isn't being used, but Google and others do this for added security after 90 days or so
class NormalLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: {
        email: '',
        type: 'info',
        show: false,
      },
      user: {
        email: '',
        password: '',
      },
      submitted: false,
    };
  }

  getValidationState = (type) => {
    const { alert } = this.state;
    switch (type) {
      case 'email': {
        if (this.state.submitted) {
          if (this.state.user.email.length === 0) {
            alert.email = 'Please enter your email';
            // this.setState({
            //   error: error
            // });
            return 'error';
          }
          // eslint-disable-next-line no-useless-escape
          const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(String(this.state.user.email).toLowerCase())) {
            alert.email = 'This is not a valid email';
            // this.setState({
            //   error: error
            // });
            return 'error';
          }
        }
        alert.email = '';
        alert.password = '';
        return null;
      }
      default: {
        return null;
      }
    }
  };

  hideAlertDialog = () => {
    this.setState({
      alert: {
        show: false,
      },
    });
  };

  handleUserChange = (key) => (e) => {
    const { user } = this.state;
    user[key] = e.target.value;
    this.setState({ user });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      submitted: true,
    });
    this.validateFields().then(async (values) => {
      console.log('Received values of form: ', values);
      await firebaseApp.auth().signInWithEmailAndPassword(values.password)
        .then(() => {
          // this.setState({
          //   alert: {
          //     show: true,
          //     title: 'Please Check Your Email',
          //     type: 'success',
          //     message: `Instructions for resetting your password have been sent to ${values.email}`,
          //     submitted: false,
          //   },
          // });
        }).catch((err) => {
          console.log(err);
          this.setState({
            alert: {
              show: true,
              title: 'There was a problem',
              type: 'error',
              message: err.message,
              submitted: false,
            },
          });
        });
    });
  };

  validateFields() {
    return new Promise((resolve, reject) => {
      if (this.getValidationState('password') === 'error') {
        reject();
      } else {
        resolve({
          password: this.state.user.password,
        });
      }
    });
  }

  renderErrorAlert = () => {
    if (isIOS) {
      const items = [{
        label: 'Dismiss',
        onClick: () => {
          this.hideAlertDialog();
        },
      }];
      return (
        <IosAlert
          show={this.state.alert.show}
          title={this.state.alert.title}
          content={this.state.alert.message}
          buttons={items}
          onDismiss={() => {}}
        />
      );
    }
    if (isAndroid) {
      const actions = [
        <FlatButton
          label="Dismiss"
          primary
          onClick={this.hideAlertDialog}
        />,
      ];
      return (
        <Dialog
          title={this.state.alert.title}
          actions={actions}
          modal={false}
          open={this.state.alert.show}
          onRequestClose={this.hideAlertDialog}
        >
          <DialogTitle id="alert-dialog-title">{this.state.alert.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.alert.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MaterialButton onClick={this.hideAlertDialog} color="secondary">
              Dismiss
            </MaterialButton>
          </DialogActions>
        </Dialog>
      );
    }
    return (
      <SweetAlert
        show={this.state.alert.show}
        title={this.state.alert.title}
        text={this.state.alert.message}
        type={this.state.alert.type}
        onConfirm={this.hideAlertDialog}
        onOutsideClick={this.hideAlertDialog}
      />
    );
    // }
  };

  render() {
    return (
      <section className="form-v1-container align-items-center">
        <div style={{ textAlign: 'center' }}>
          <img
            src={logoSrc}
            alt="Boxpressd"
            style={{ maxWidth: 220, marginBottom: 20 }}
          />
        </div>
        <p className="lead dark" style={{ color: isMobile ? '#efefef' : 'inherit' }}>Confirm Your Account</p>
        <p className="dark" style={{ color: isMobile ? '#efefef' : 'inherit', marginTop: -10, marginBottom: 20 }}>Enter your password for the account associated with <strong>{this.state.user.email}</strong>.</p>

        <form onSubmit={this.handleSubmit} className="form-v1">
          <FormGroup validationState={this.getValidationState('email')}>
            <Input prefix={<Icon icon="user" style={{ fontSize: 13 }} />} type="password" placeholder="Password" value={this.state.user.password} onChange={this.handleUserChange('password')} />
            <FormText>{this.state.alert.password}</FormText>
          </FormGroup>
          <Button type="submit" htmlType="submit" className="btn-cta btn-block btn-primary">Continue</Button>
        </form>

        { this.renderErrorAlert() }
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)(NormalLoginForm));
