import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import SweetAlert from 'sweetalert-react';
import Button from 'reactstrap-button-loader';
import {isAndroid, isIOS, isMobile, isMobileOnly} from 'react-device-detect';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import MaterialButton from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import IosAlert from '../../components/IosModal';
import Icon from '../../components/Icon';
import { signInUser, signInWithFacebook, signInWithGoogle } from '../actions';
import { language, messages } from '../../utils/localeUtils';
import inputStyle from '../../utils/inputStyle';

const env = process.env.NODE_ENV || 'development';
const dev = env === 'development';


const serialize = function(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p) && obj[p] !== null) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

function NormalLoginForm(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectPath = urlParams.get('path');
  const source = urlParams.get('source');
  const logoSrc = source && source === 'cnm'
    // TODO Mobile only needs white "powered by" logo
    ? (isMobileOnly ? 'https://cdn.cigarsnearme.com/assets/logos/cnm-powered-by-dark.png' : 'https://cdn.cigarsnearme.com/assets/logos/cnm-powered-by.png')
    : 'https://boxpressd.com/4a1bc988e13abc66d4e8c1ad9bb0f61b.png';

  const searchParams = {
    path: redirectPath,
    source,
  };

  const searchStr = serialize(searchParams);

  const CreateAccount = () => (
    <div className="create-account">
      <p className="additional-info" style={{ color: isMobile ? '#efefef' : 'inherit', fontSize: '1em' }}>
        {messages[language]?.no_account || 'Don\'t have an account yet?'}
      </p>
      <Link to={{ pathname: '/register', search: searchStr.length ? `?${searchStr}` : null }}>
        <Button className="btn-cta btn-block btn-success" style={{ fontSize: '1.1em', lineHeight: '16px' }}>{messages[language]?.create_an_account || 'Create An Account'}</Button>
      </Link>
    </div>
  );

  const [emailErrorHint, setEmailErrorHint] = useState(null);
  const [passwordErrorHint, setPasswordErrorHint] = useState(null);
  const [user, setUser] = useState({
    email: '',
    password: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (props.authError && props.authError.message) {
      if (props.authError.message.indexOf('The popup has been closed by the user before finalizing the operation') === -1) {
        setShowError(props.authError?.show);
      }
    }
  }, [props.authError]);

  const valid = (type) => {
    switch (type) {
      case 'email': {
        if (user.email.length === 0) {
          setEmailErrorHint('Please enter your email');
          return false;
        }
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(user.email).toLowerCase())) {
          setEmailErrorHint('This is not a valid email');
          return false;
        }
        setEmailErrorHint(null);
        return true;
      }
      case 'password': {
        if (user.password.length === 0) {
          setPasswordErrorHint('Please enter your password');
          return false;
        }
        setPasswordErrorHint(null);
        return true;
      }
      default: {
        return false;
      }
    }
  };

  const showChat = () => {
    document.querySelector('#fc_frame').style.display = 'block';
    if (window.fcWidget) {
      console.debug('Found chat widget');
      window.fcWidget.open({ name: 'Get Help' });
    }
    hideAlertDialog();
  };

  const hideAlertDialog = () => {
    props.authError.show = false;
    setShowError(false);
  };

  const handleUserChange = (key) => (e) => {
    const updatedUser = { ...user };
    updatedUser[key] = e.target.value;
    setUser(updatedUser);
  };

  const handleFacebookLogin = () => {
    props.signInWithFacebook((tokenId, err) => {
      // This is called whether it fails or succeeds
      setLoading(false);
      console.log('Logged in!');
      if (tokenId && !err) {
        window.redirect();
      }
    });
  };

  const handleGoogleLogin = () => {
    props.signInWithGoogle((tokenId, err) => {
      // This is called whether it fails or succeeds
      setLoading(false);
      if (tokenId && !err) {
        window.redirect();
      }
    });
  };

  const handleSubmit = () => {
    setSubmitted(true);
    validateFields().then(async (values) => {
      setLoading(true);
      console.log('Received valid values of form: ', values);
      // INFO Look into redux-thunk to run straight in reducer
      props.signInUser(values, (tokenId, err) => {
        setLoading(false);
        if (tokenId && !err) {
          window.redirect();
        }
      });
    }).catch(() => {
      setLoading(false);
    });
  };

  const handleEnterPress = (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      handleSubmit();
    }
  };

  const validateFields = () => new Promise((resolve, reject) => {
    if (!valid('email') || !valid('password')) {
      reject();
    } else {
      resolve({
        email: user.email,
        password: user.password,
      });
    }
  });

  const translateMessage = (message) => {
    if (!message) {
      return 'A problem occurred. Please try again or contact support.';
    }
    if (message.trim() === 'The email address is already in use by another account.') {
      return 'The email address is already in use by another account. You may have signed up using Google or Facebook.';
    }
    return message;
  };

  const renderErrorAlert = () => {
    if (isMobile) {
      if (isIOS) {
        const items = [{
          label: 'Get Help',
          onClick: () => {
            hideAlertDialog();
            showChat();
          },
        }, {
          label: 'Dismiss',
          onClick: () => {
            hideAlertDialog();
          },
        }];
        return (
          <IosAlert
            show={showError}
            title={props.authError.title}
            content={translateMessage(props.authError.message)}
            buttons={items}
            onDismiss={() => {
            }}
          />
        );
      } if (isAndroid) {
        const actions = [
          <MaterialButton
            label="Get Help"
            primary
            onClick={showChat}
          />,
          <MaterialButton
            label="Dismiss"
            primary
            onClick={hideAlertDialog}
          />,
        ];
        return (
          <Dialog
            title={props.authError.title}
            actions={actions}
            modal={false}
            open={showError}
            onRequestClose={hideAlertDialog}
          >
            <DialogTitle id="alert-dialog-title">Unable To Log In</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {translateMessage(props.authError.message)}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MaterialButton onClick={showChat} color="default">
                Get Help
              </MaterialButton>
              <MaterialButton onClick={hideAlertDialog} color="secondary">
                Dismiss
              </MaterialButton>
            </DialogActions>
          </Dialog>
        );
      }
    }
    return (
      <SweetAlert
        show={showError}
        title={props.authError.title}
        text={translateMessage(props.authError.message)}
        type="error"
        showCancelButton
        showConfirmButton
        onCancel={showChat}
        cancelButtonText="Get Help"
        onConfirm={hideAlertDialog}
        confirmButtonText="Dismiss"
        onOutsideClick={hideAlertDialog}
      />
    );
  };

  return (
    <section className="align-items-center">
      <div style={{ paddingTop: 16, marginLeft: 26 }}>
        <img
          src={logoSrc}
          alt="Boxpressd"
          style={{ maxWidth: 220 }}
        />
      </div>
      <p className="lead dark align-items-center" style={{ marginTop: 20, marginBottom: 20, color: dev ? '#ff0000' : (isMobile ? '#efefef' : 'inherit') }}>
        {dev ? 'FOR DEVELOPMENT PURPOSES ONLY' : (messages[language]?.welcome_back || 'Welcome back, sign in with your Boxpressd account') }
      </p>

      <div style={{ /* backgroundColor: 'rgba(0, 0, 0, 0.4)', */paddingTop: 20, paddingBottom: 20 }}>
        <div style={{ width: '100%', marginBottom: 8 }}>
          <TextField
            name="email"
            type="email"
            error={submitted && emailErrorHint}
            style={{ width: '100%' }}
            inputProps={{ style: inputStyle }}
            // variant="standard"
            label={messages[language]?.email || 'Email'}
            helperText={emailErrorHint || ''}
            value={user.email}
            onChange={handleUserChange('email')}
            onKeyPress={handleEnterPress}
          />
        </div>
        <div style={{ width: '100%', marginTop: 20 }}>
          <TextField
            name="password"
            error={submitted && passwordErrorHint}
            style={{ width: '100%' }}
            inputProps={{ style: inputStyle }}
            label={messages[language]?.password || 'Password'}
            type={showPassword ? 'text' : 'password'}
            // variant="standard"
            helperText={passwordErrorHint || ''}
            autoComplete="current-password"
            value={user.password}
            onChange={handleUserChange('password')}
            onKeyPress={handleEnterPress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prevValue) => !prevValue)}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff style={{ height: '1.2em', width: '1.2em' }} /> : <Visibility style={{ height: '1.2em', width: '1.2em' }} />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <p className="additional-info" style={{ color: isMobile ? '#efefef' : 'inherit', fontSize: '1em', marginTop: 12, textAlign: 'center' }}>
          <Link to={{ pathname: '/forgot-password', search: `?path=${redirectPath}` }}>{messages[language]?.forgot_password || 'Forgot password?'}</Link>
        </p>
        {/* <FormGroup className="form-v1__remember"> */}
        {/*  <FormControlLabel */}
        {/*    style={{ color: isMobile ? '#efefef' : 'inherit', fontSize: 12 }} */}
        {/*    control={ */}
        {/*      <Checkbox style={{ color: isMobile ? '#efefef' : 'inherit' }} /> */}
        {/*    } */}
        {/*    label="Remember me" */}
        {/*  /> */}
      </div>
      <Button
        type="submit"
        className="btn-cta btn-block btn-primary"
        loading={loading}
        disabled={loading}
        style={{ fontSize: '1.1em' }}
        onClick={handleSubmit}
      >
        {messages[language]?.log_in || 'Log In'}
      </Button>

      <div className={isMobile ? 'divider divider-with-content-inverse my-4' : 'divider divider-with-content my-4'}>
        <span className="divider-inner-content dark" style={{ color: isMobile ? '#efefef' : 'inherit' }}>or</span>
      </div>

      <button disabled={loading} type="button" className="btn btn-block icon-btn-v2 bg-facebook mb-2" onClick={handleFacebookLogin} style={{ fontSize: '1em' }}>
        <Icon name={['fab', 'facebook']} vendor="fa" style={{ width: 16, height: 16, marginRight: 6, marginTop: -3 }} />
        <span className="btn-text">{`${messages[language]?.continue_with || 'Continue with'} Facebook`}</span>
      </button>
      <button disabled={loading} type="button" className="btn btn-block icon-btn-v2 bg-google mb-2" onClick={handleGoogleLogin} style={{ fontSize: '1em', marginTop: 12 }}>
        <Icon name={['fab', 'google']} vendor="fa" style={{ width: 16, height: 16, marginRight: 15, marginTop: -3 }} />
        <span className="btn-text" style={{ marginRight: 9 }}>{`${messages[language]?.continue_with || 'Continue with'} Google`}</span>
      </button>
      {/* <button className="btn btn-block icon-btn-v2 bg-twitter"><Icon type="twitter" /><span className="btn-text">Login with Twitter</span></button> */}

      <CreateAccount />

      {renderErrorAlert()}
    </section>
  );
}

NormalLoginForm.propTypes = {
  authError: PropTypes.object,
  signInUser: PropTypes.func,
  signInWithFacebook: PropTypes.func,
  signInWithGoogle: PropTypes.func,
};

NormalLoginForm.defaultProps = {
  authError: {},
  signInUser: () => {},
  signInWithFacebook: () => {},
  signInWithGoogle: () => {},
};

const mapStateToProps = (state) => ({
  authError: state.getIn(['auth', 'error']),
});

const mapDispatchToProps = (dispatch) => ({
  signInUser: (values, callback) => dispatch(signInUser(values, callback)),
  signInWithFacebook: (callback) => dispatch(signInWithFacebook(callback)),
  signInWithGoogle: (callback) => dispatch(signInWithGoogle(callback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NormalLoginForm));
