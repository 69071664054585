import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import SweetAlert from 'sweetalert-react';
import { Button } from 'reactstrap';
import {isAndroid, isIOS, isMobile, isMobileOnly} from 'react-device-detect';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import FlatButton from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import MaterialButton from '@material-ui/core/Button/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import IosAlert from '../../components/IosModal';
import firebaseApp from '../../../config/firebase.config';
import inputStyle from '../../utils/inputStyle';

function NormalLoginForm(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectPath = urlParams.get('path');
  const oobCode = urlParams.get('oobCode');
  const source = urlParams.get('source');
  const logoSrc = source && source === 'cnm'
    ? (isMobileOnly ? 'https://cdn.cigarsnearme.com/assets/logos/cnm-powered-by-dark.png' : 'https://cdn.cigarsnearme.com/assets/logos/cnm-powered-by.png')
    : 'https://boxpressd.com/4a1bc988e13abc66d4e8c1ad9bb0f61b.png';

  const [alert, setAlert] = useState({
    email: '',
    type: 'info',
    show: false,
  });
  const [user, setUser] = useState({
    email: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [passwordErrorHint, setPasswordErrorHint] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (oobCode) {
      firebaseApp.auth().verifyPasswordResetCode(oobCode).then((email) => {
        setUser({ ...user, email });
        setLoading(false);
        setValid(true);
      }).catch(() => {
        setLoading(false);
        setValid(false);
      });
    } else {
      setLoading(false);
      setValid(false);
    }
  }, []);

  const getValidationState = (type) => {
    switch (type) {
      case 'email': {
        if (submitted) {
          if (user.email.length === 0) {
            alert.email = 'Please enter your email';
            // setState({
            //   error: error
            // });
            return 'error';
          }
          // eslint-disable-next-line no-useless-escape
          const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!re.test(String(user.email).toLowerCase())) {
            alert.email = 'This is not a valid email';
            // setState({
            //   error: error
            // });
            return 'error';
          }
        }
        alert.email = '';
        alert.password = '';
        return null;
      }
      default: {
        return null;
      }
    }
  };

  const hideAlertDialog = () => {
    setAlert((prevAlert) => ({
      ...prevAlert,
      show: false,
    }));
  };

  const handleUserChange = (key) => (e) => {
    const updatedUser = { ...user };
    updatedUser[key] = e.target.value;
    setUser(updatedUser);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    validateFields().then(async (values) => {
      console.log('Received values of form: ', values);
      await firebaseApp.auth().confirmPasswordReset(oobCode, user.password).then(() => {
        setAlert({
          show: true,
          title: 'Password Updated',
          type: 'success',
          message: 'Your password was successfully reset.',
          submitted: false,
        });
      }).catch((err) => {
        console.log(err);
        setAlert({
          show: true,
          title: 'There was a problem',
          type: 'error',
          message: err.message,
          submitted: false,
        });
      });
    });
  };

  const validateFields = () => new Promise((resolve, reject) => {
    if (getValidationState('email') === 'error') {
      reject();
    } else {
      resolve({
        email: user.email,
      });
    }
  });

  const renderErrorAlert = () => {
    if (isIOS) {
      const items = [{
        label: 'Dismiss',
        onClick: () => {
          hideAlertDialog();
        },
      }];
      return (
        <IosAlert
          show={alert.show}
          title={alert.title}
          content={alert.message}
          buttons={items}
          onDismiss={() => {}}
        />
      );
    }
    if (isAndroid) {
      const actions = [
        <FlatButton
          label="Dismiss"
          primary
          onClick={hideAlertDialog}
        />,
      ];
      return (
        <Dialog
          title={alert.title}
          actions={actions}
          modal={false}
          open={alert.show}
          onRequestClose={hideAlertDialog}
        >
          <DialogTitle id="alert-dialog-title">{alert.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {alert.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MaterialButton onClick={hideAlertDialog} color="secondary">
              Dismiss
            </MaterialButton>
          </DialogActions>
        </Dialog>
      );
    }
    return (
      <SweetAlert
        show={alert.show}
        title={alert.title}
        text={alert.message}
        type={alert.type}
        onConfirm={hideAlertDialog}
        onOutsideClick={hideAlertDialog}
      />
    );
  };

  if (loading) {
    return (
      <section className="form-v1-container align-items-center">
        <div style={{ textAlign: 'center' }}>
          <img
            src={logoSrc}
            alt="Boxpressd"
            style={{ maxWidth: 220, marginBottom: 30 }}
          />
          <div>
            <CircularProgress />
          </div>
        </div>
      </section>
    );
  }
  if (!loading && !valid) {
    return (
      <section className="form-v1-container align-items-center">
        <div style={{ textAlign: 'center' }}>
          <img
            src={logoSrc}
            alt="Boxpressd"
            style={{ maxWidth: 220, marginBottom: 20 }}
          />
        </div>
        <p className="lead dark" style={{ color: isMobile ? '#efefef' : 'inherit', fontSize: '1.2em' }}>
          {'Try resetting your password again'}
        </p>
        <p className="dark" style={{ color: isMobile ? '#efefef' : 'inherit', marginTop: -10, marginBottom: 20 }}>
          {'Your request to reset your password has expired or the link has already been used'}
        </p>
      </section>
    );
  }
  return (
    <section className="form-v1-container align-items-center">
      <div style={{ textAlign: 'center' }}>
        <img
          src={logoSrc}
          alt="Boxpressd"
          style={{ maxWidth: 220, marginBottom: 20 }}
        />
      </div>
      <p className="lead dark" style={{ color: isMobile ? '#efefef' : 'inherit', fontSize: '1.2em' }}>
        {'Reset your password'}
      </p>
      <p className="dark" style={{ color: isMobile ? '#efefef' : 'inherit', marginTop: -10, marginBottom: 20 }}>
        {`for ${user.email}`}
      </p>

      <TextField
        name="password"
        error={submitted && passwordErrorHint}
        style={{ width: '100%' }}
        inputProps={{ style: inputStyle }}
        label="Password"
        type={showPassword ? 'text' : 'password'}
        // variant="standard"
        helperText={passwordErrorHint || ''}
        autoComplete="current-password"
        value={user.password}
        onChange={handleUserChange('password')}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((prevValue) => !prevValue)}
                // onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        style={{ marginTop: 20 }}
        className="btn-cta btn-block btn-primary"
        onClick={handleSubmit}
      >
        {'Save'}
      </Button>
      <p className="additional-info" style={{ color: isMobile ? '#efefef' : 'inherit', fontSize: '1em', marginTop: 12, textAlign: 'center' }}>
        <Link to={{ pathname: '/login', search: `?path=${redirectPath}` }}>Back to Login</Link>
      </p>

      { renderErrorAlert() }
    </section>
  );
}

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default withRouter(connect(null, mapDispatchToProps)(NormalLoginForm));
