import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import SweetAlert from 'sweetalert-react';
import { FormFeedback, FormGroup } from 'reactstrap';
import Button from 'reactstrap-button-loader';
import {isAndroid, isIOS, isMobile, isMobileOnly} from 'react-device-detect';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import MaterialButton from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Icon from '../../components/Icon';
import { signInWithFacebook, signInWithGoogle, signUpUser } from '../actions';
import IosAlert from '../../components/IosModal';
import { language, messages } from '../../utils/localeUtils';
import inputStyle from '../../utils/inputStyle';

const env = process.env.NODE_ENV || 'development';
const dev = env === 'development';

function NormalSignUpForm(props) {
  const [checked, setChecked] = useState(false);
  const [firstNameErrorHint, setFirstNameErrorHint] = useState(null);
  const [lastNameErrorHint, setLastNameErrorHint] = useState(null);
  const [emailErrorHint, setEmailErrorHint] = useState(null);
  const [passwordErrorHint, setPasswordErrorHint] = useState(null);
  const [termsErrorHint, setTermsErrorHint] = useState(null);
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirm: '',
    firebase_id: null,
  });
  const [submitted, setSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (props.authError && props.authError.message) {
      if (props.authError.message.indexOf('The popup has been closed by the user before finalizing the operation') === -1) {
        setShowError(props.authError?.show);
      }
    }
  }, [props.authError]);

  const valid = (type) => {
    switch (type) {
      case 'first_name': {
        if (!user.first_name.length) {
          setFirstNameErrorHint('Please enter your first name');
          console.log('Invalid first name...');
          return false;
        }
        setFirstNameErrorHint(null);
        return true;
      }
      case 'last_name': {
        if (!user.last_name.length) {
          setLastNameErrorHint('Please enter your last name');
          console.log('Invalid last name...');
          return false;
        }
        setLastNameErrorHint(null);
        return true;
      }
      case 'email': {
        if (!user.email.length) {
          setEmailErrorHint('Please enter your email');
          console.log('Missing email...');
          return false;
        }
        // eslint-disable-next-line no-useless-escape
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(user.email.trim()).toLowerCase())) {
          setEmailErrorHint('This is not a valid email');
          console.log('Invalid email...');
          return false;
        }
        setEmailErrorHint(null);
        return true;
      }
      case 'password': {
        if (user.password.length === 0) {
          setPasswordErrorHint('Please enter your password');
          console.log('Missing password...');
          return false;
        }
        setPasswordErrorHint(null);
        return true;
      }
      case 'terms': {
        if (!checked) {
          setTermsErrorHint('Please accept the terms and conditions.');
        } else {
          setTermsErrorHint(null);
        }
        return checked;
      }
      default: {
        return false;
      }
    }
  };

  const showChat = () => {
    document.querySelector('#fc_frame').style.display = 'block';
    if (window.fcWidget) {
      window.fcWidget.open({ name: 'Get Help' });
    }
    hideAlertDialog();
  };

  const hideAlertDialog = () => {
    props.authError.show = false;
    setShowError(false);
  };

  const handleUserChange = (key) => (e) => {
    const updatedUser = { ...user };
    updatedUser[key] = e.target.value;
    setUser(updatedUser);
  };

  const toggleChecked = () => {
    setChecked((prevValue) => !prevValue);
  };

  const handleSubmit = () => {
    setSubmitted(true);
    validateFields().then(async (values) => {
      setLoading(true);
      console.log('Received valid values of form: ', values);
      props.signUpUser({
        ...values,
        source,
      }, (tokenId, err) => {
        setLoading(false);
        if (tokenId && !err) {
          window.redirect();
        }
      });
    }).catch(() => {
      setLoading(false);
      // setShowError(true);
    });
  };

  const handleEnterPress = (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      handleSubmit();
    }
  };

  const handleFacebookLogin = () => {
    setLoading(true);
    props.signInWithFacebook((tokenId, err) => {
      // This is called whether it fails or succeeds
      setLoading(false);
      if (tokenId && !err) {
        window.redirect();
      }
    });
  };

  const handleGoogleLogin = () => {
    setLoading(true);
    props.signInWithGoogle((tokenId, err) => {
      // This is called whether it fails or succeeds
      setLoading(false);
      if (tokenId && !err) {
        window.redirect();
      }
    });
  };

  const validateFields = () => new Promise((resolve, reject) => {
    if (
      !valid('first_name')
      || !valid('last_name')
      || !valid('email')
      || !valid('password')
      || !valid('terms')
    ) {
      console.log('Rejecting promise...'); // FIXME This isn't running - not sure I like the 'error' response anyway, code this over
      reject();
    } else {
      resolve({
        first_name: user.first_name.trim(),
        last_name: user.last_name.trim(),
        email: user.email.trim(),
        password: user.password.trim(),
      });
    }
  });

  const translateMessage = (message) => {
    if (!message) {
      return 'A problem occurred. Please try again or contact support.';
    }
    if (message.trim() === 'The email address is already in use by another account.') {
      return 'The email address is already in use by another account. You may have signed up using Google or Facebook.';
    }
    return message;
  };

  const renderErrorAlert = () => {
    if (isMobile) {
      if (isIOS) {
        const items = [{
          label: 'Get Help',
          onClick: () => {
            hideAlertDialog();
            showChat();
          },
        }, {
          label: 'Dismiss',
          onClick: () => {
            hideAlertDialog();
          },
        }];
        return (
          <IosAlert
            show={showError}
            title={props.authError.title}
            content={translateMessage(props.authError.message)}
            buttons={items}
            onDismiss={() => {}}
          />
        );
      } if (isAndroid) {
        const actions = [
          <MaterialButton
            label="Get Help"
            primary
            onClick={showChat}
          />,
          <MaterialButton
            label="Dismiss"
            primary
            onClick={hideAlertDialog}
          />,
        ];
        return (
          <Dialog
            title={props.authError.title}
            actions={actions}
            modal={false}
            open={showError}
            onRequestClose={hideAlertDialog}
          >
            <DialogTitle id="alert-dialog-title">Unable To Sign Up</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {translateMessage(props.authError.message)}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MaterialButton onClick={showChat} color="default">
                Get Help
              </MaterialButton>
              <MaterialButton onClick={hideAlertDialog} color="secondary">
                Dismiss
              </MaterialButton>
            </DialogActions>
          </Dialog>
        );
      }
    }
    return (
      <SweetAlert
        show={showError}
        title={props.authError.title}
        text={translateMessage(props.authError.message)}
        type="error"
        showCancelButton
        showConfirmButton
        onCancel={showChat}
        cancelButtonText="Get Help"
        onConfirm={hideAlertDialog}
        confirmButtonText="Dismiss"
        onOutsideClick={hideAlertDialog}
      />
    );
  };

  const urlParams = new URLSearchParams(window.location.search);
  const redirectPath = urlParams.get('path');
  const source = urlParams.get('source');
  const logoSrc = source && source === 'cnm'
    ? (isMobileOnly ? 'https://cdn.cigarsnearme.com/assets/logos/cnm-powered-by-dark.png' : 'https://cdn.cigarsnearme.com/assets/logos/cnm-powered-by.png')
    : 'https://boxpressd.com/4a1bc988e13abc66d4e8c1ad9bb0f61b.png';

  return (
    <section className="align-items-center">
      <div style={{ maxWidth: 550 }}>
        <div style={{ textAlign: 'center' }}>
          <img
            src={logoSrc}
            alt="Boxpressd"
            style={{ maxWidth: 220 }}
          />
        </div>
        <p className="lead dark" style={{ color: isMobile ? '#efefef' : 'inherit', marginTop: 12 }}>{messages[language]?.create_boxpressd_account || 'Create your Boxpressd account'}</p>
        <p className="dark" style={{ color: dev ? '#ff0000' : (isMobile ? '#efefef' : 'inherit'), marginTop: -10, marginBottom: 20 }}>
          { dev ? 'FOR DEVELOPMENT PURPOSES ONLY' : (messages[language]?.one_account || 'One account, all of our great services') }
        </p>

        <button disabled={loading} type="button" className="btn btn-block icon-btn-v2 bg-facebook mb-2" onClick={handleFacebookLogin}>
          <Icon name={['fab', 'facebook']} vendor="fa" style={{ width: 16, height: 16, marginRight: 6, marginTop: -3 }} />
          <span className="btn-text">{`${messages[language]?.continue_with || 'Continue with'} Facebook`}</span>
        </button>
        <button disabled={loading} type="button" className="btn btn-block icon-btn-v2 bg-google mb-2" onClick={handleGoogleLogin} style={{ marginTop: 12 }}>
          <Icon name={['fab', 'google']} vendor="fa" style={{ width: 16, height: 16, marginRight: 15, marginTop: -3 }} />
          <span className="btn-text" style={{ marginRight: 9 }}>{`${messages[language]?.continue_with || 'Continue with'} Google`}</span>
        </button>
        {/* <button className="btn btn-block icon-btn-v2 bg-twitter"><Icon type="twitter" /><span className="btn-text">Login with Twitter</span></button> */}

        <div className={isMobile ? 'divider divider-with-content-inverse my-4' : 'divider divider-with-content my-4'}>
          <span className="divider-inner-content dark" style={{ color: isMobile ? '#efefef' : 'inherit' }}>
            {'or'}
          </span>
        </div>

        <div style={{ width: '100%', marginBottom: 8, display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1, marginRight: 4 }}>
            <TextField
              name="first_name"
              error={submitted && firstNameErrorHint}
              style={{ width: '100%' }}
              inputProps={{ style: inputStyle }}
              // variant="standard"
              label={messages[language]?.first_name || 'First Name'}
              helperText={firstNameErrorHint || ''}
              value={user.first_name}
              onChange={handleUserChange('first_name')}
              onKeyPress={handleEnterPress}
            />
          </div>
          <div style={{ flex: 1, marginLeft: 4 }}>
            <TextField
              name="last_name"
              error={submitted && lastNameErrorHint}
              style={{ width: '100%' }}
              inputProps={{ style: inputStyle }}
              // variant="standard"
              label={messages[language]?.last_name || 'Last Name'}
              helperText={lastNameErrorHint || ''}
              value={user.last_name}
              onChange={handleUserChange('last_name')}
              onKeyPress={handleEnterPress}
            />
          </div>
        </div>
        <div style={{ width: '100%', marginBottom: 8, marginTop: 20 }}>
          <TextField
            name="email"
            type="email"
            error={submitted && emailErrorHint}
            style={{ width: '100%' }}
            inputProps={{ style: inputStyle }}
            // variant="standard"
            label={messages[language]?.email || 'Email'}
            helperText={emailErrorHint || ''}
            value={user.email}
            onChange={handleUserChange('email')}
            onKeyPress={handleEnterPress}
          />
        </div>
        <div style={{ width: '100%', marginTop: 20, marginBottom: 20 }}>
          <TextField
            name="password"
            error={submitted && passwordErrorHint}
            style={{ width: '100%' }}
            inputProps={{ style: inputStyle }}
            label={messages[language]?.password || 'Password'}
            type={showPassword ? 'text' : 'password'}
            // variant="standard"
            helperText={passwordErrorHint || ''}
            autoComplete="current-password"
            value={user.password}
            onChange={handleUserChange('password')}
            onKeyPress={handleEnterPress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prevValue) => !prevValue)}
                    // onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff style={{ height: '1.2em', width: '1.2em' }} /> : <Visibility style={{ height: '1.2em', width: '1.2em' }} />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        {/* <Form noValidate onSubmit={handleSubmit} className="form-v1"> */}
        {/*  <FormGroup validationState={valid('first_name')}> */}
        {/*    <Input invalid={submitted && error.first_name} prefix={<Icon name="user" style={{ fontSize: 13 }} />} type="text" placeholder="First Name" value={user.first_name} onChange={handleUserChange('first_name')} /> */}
        {/*    <FormFeedback invalid>{submitted && error.first_name}</FormFeedback> */}
        {/*  </FormGroup> */}
        {/*  <FormGroup validationState={valid('last_name')}> */}
        {/*    <Input invalid={submitted && error.last_name} prefix={<Icon name="user" style={{ fontSize: 13 }} />} type="text" placeholder="Last Name" value={user.last_name} onChange={handleUserChange('last_name')} /> */}
        {/*    <FormFeedback invalid>{submitted && error.last_name}</FormFeedback> */}
        {/*  </FormGroup> */}
        {/*  <FormGroup validationState={valid('email')}> */}
        {/*    <Input invalid={submitted && error.email} prefix={<Icon name="user" style={{ fontSize: 13 }} />} type="email" placeholder="Email" value={user.email} onChange={handleUserChange('email')} /> */}
        {/*    <FormFeedback invalid>{submitted && error.email}</FormFeedback> */}
        {/*  </FormGroup> */}
        {/*  <FormGroup validationState={valid('password')}> */}
        {/*    <Input invalid={submitted && error.password} prefix={<Icon name="lock" style={{ fontSize: 13 }} />} type="password" placeholder="Password" value={user.password} onChange={handleUserChange('password')} /> */}
        {/*    <FormFeedback invalid>{submitted && error.password}</FormFeedback> */}
        {/*  </FormGroup> */}
        {/*  <FormGroup validationState={valid('password_confirm')}> */}
        {/*    <Input invalid={submitted && error.password_confirm} prefix={<Icon name="lock" style={{ fontSize: 13 }} />} type="password" placeholder="Password (Confirm)" value={user.password_confirm} onChange={handleUserChange('password_confirm')} /> */}
        {/*    <FormFeedback invalid>{submitted && error.password_confirm}</FormFeedback> */}
        {/*  </FormGroup> */}
        {/* </Form> */}
        <FormGroup validationState={termsErrorHint} className="form-v1__remember">
          <FormControlLabel
            style={{ color: isMobile ? '#efefef' : 'inherit', fontSize: 12 }}
            control={
              <Checkbox value={checked} onChange={toggleChecked} style={{ color: isMobile ? '#efefef' : 'inherit' }} />
            }
            label={(
              <div>
                {messages[language]?.accept_terms || 'By checking this box I confirm that I am 21 years of age or older and agree to the'}
                {' '}
                <a href="https://boxpressd.com/legal/terms" target="_blank">
                  {messages[language]?.terms_of_service || 'Terms of Service'}
                </a>
              </div>
            )}
          />
          <FormFeedback invalid>{submitted && termsErrorHint}</FormFeedback>
        </FormGroup>
        <Button
          disabled={loading}
          className="btn-cta btn-block btn-primary"
          loading={loading}
          onClick={handleSubmit}
        >
          {messages[language]?.sign_up || 'Sign Up'}
        </Button>
        <div style={{ marginTop: 20, marginBottom: 30 }}>
          <p className="additional-info" style={{ color: isMobile ? '#efefef' : 'inherit', fontSize: '1em' }}>
            {messages[language]?.already_have_account || 'Already have an account?'}
            {' '}
            <Link to={{ pathname: '/login', search: redirectPath ? `?path=${redirectPath}` : null }}>
              {messages[language]?.log_in || 'Log In'}
            </Link>
          </p>
          <p className="additional-info" style={{ color: isMobile ? '#efefef' : 'inherit', fontSize: '1em' }}>
            {messages[language]?.forgot_your_password || 'Forgot your password?'}
            {' '}
            <Link to={{ pathname: '/forgot-password', search: `?path=${redirectPath}` }}>
              {messages[language]?.reset_password || 'Reset password'}
            </Link>
          </p>
        </div>

        { renderErrorAlert() }
      </div>
    </section>
  );
}

NormalSignUpForm.propTypes = {
  authError: PropTypes.object,
  signUpUser: PropTypes.func,
  signInWithFacebook: PropTypes.func,
  signInWithGoogle: PropTypes.func,
};

NormalSignUpForm.defaultProps = {
  authError: {},
  signUpUser: () => {},
  signInWithFacebook: () => {},
  signInWithGoogle: () => {},
};

const mapStateToProps = (state) => ({
  authError: state.getIn(['auth', 'error']),
});

const mapDispatchToProps = (dispatch) => ({
  signUpUser: (values, callback) => dispatch(signUpUser(values, callback)),
  signInWithFacebook: (callback) => dispatch(signInWithFacebook(callback)),
  signInWithGoogle: (callback) => dispatch(signInWithGoogle(callback)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NormalSignUpForm));
