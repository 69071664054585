import { AUTH_USER, SIGN_OUT_USER, AUTH_ERROR } from './actions';
import { fromJS } from 'immutable';
import ls from 'local-storage';
import Cookies from 'universal-cookie';
import firebaseApp from '../../config/firebase.config';
const cookies = new Cookies();

const authState = fromJS({
  user: null,
  authenticated: false,
  token_id: null,
  // redirect: false,
  error: {
    show: false,
    title: '',
    message: '',
  },
});

export default function authReducer(state = authState, action) {
  switch (action.type) {
    case AUTH_USER: {
      const currentUser = action.user;

      console.log('Storing logged in user to local storage');
      ls.set('authenticated', true);
      ls.set('user', currentUser);
      ls.set('token_id', action.token_id);
      ls.set('firebase_id', firebaseApp.auth().currentUser.uid);
      console.log('Stored logged in user to local storage');

      cookies.set('tokenId', action.token_id, { /* path: '/' */ });

      if (typeof action.callback === 'function') {
        console.log('Running callback for success');
        action.callback(action.token_id);
      }

      return state
        .set('error', {
          // redirect: true,
          show: false,
          title: '',
          message: '',
        });
    }
    case AUTH_ERROR: {
      // ls.set('authenticated', false);
      // ls.set('user', null);
      // ls.set('token_id', null);
      // window.sessionStorage.clear();
      // window.localStorage.clear();

      if (typeof action.callback === 'function') {
        console.log('Running callback for error');
        action.callback(null, action.error);
      }

      return state.set('error', action.error);
    }
    case SIGN_OUT_USER: {
      ls.clear();
      cookies.set('tokenId', null, { path: '/' });

      if (typeof action.callback === 'function') {
        console.log('Running callback for sign out');
        action.callback();
      }

      return state;
    }
    default: {
      return state;
    }
  }
}
